<template>
  <div class="tick-details-outer ticket-request">
    <!-- <van-nav-bar
      title="券申请"
      left-text=""
      left-arrow
      @click-left="onClickLeft"
    /> -->

    <div class="detail-con-outer">
      <div class="d-title">
        <span>{{ paramsObj.brandName }}</span>
        <span v-if="paramsObj.categoryName">/{{ paramsObj.categoryName }}</span>
        <img
          src="../../assets/image/dtj.png"
          class="img"
          v-if="paramsObj.brandType == '2'"
        />
        <img
          src="../../assets/image/zu1.png"
          class="img"
          v-if="paramsObj.brandType == '3'"
        />
      </div>
      <div class="base-detail-outer">
        <!-- <div class="info-txt">
          <span>基本信息</span>
        </div> -->
        <div class="line-row">
          <span>基本信息</span>
          <!-- <el-divider>基本信息</el-divider> -->
        </div>
        <div class="line">
          <span class="line-title">券介质：</span>
          <span>{{ paramsObj.attributeType == "1" ? "纸质" : "电子" }}</span>
        </div>
        <!-- <div class="line">
          <span class="line-title">空白券：</span>
          <span>是</span>
        </div> -->
        <div class="line" v-if="paramsObj.carSelectType == '1'">
          <span class="line-title">用途属性：</span>
          <span>{{
            paramsObj.useType == "1"
              ? "销售"
              : paramsObj.useType == "2"
              ? "免费"
              : "工具"
          }}</span>
        </div>
        <div class="line" v-if="paramsObj.brandPrice">
          <span class="line-title">面额：</span>
          <span>¥{{ paramsObj.brandPrice }}</span>
        </div>
        <!-- 能指定生效日期则显示，否则就不显示啥 -->
        <!-- console.log("有没有指定的生效日期" + this.paramsObj.effectTime); -->
        <template>
          <div class="line">
            <span class="line-title">生效环节：</span>
            <div class="requst-day-select">
              <span>{{
                paramsObj.effectTime ? "指定生效时间" : "销售/发放"
              }}</span>
            </div>
          </div>
          <div class="line" v-if="paramsObj.effectTime">
            <span class="line-title">指定生效日期：</span>
            <div class="requst-day-select">
              <span>{{ paramsObj.effectTime }}</span>
            </div>
          </div>
        </template>
        <!-- 可以修改有效期 -->
        <template v-if="false">
          <div class="line">
            <span class="line-title">效期类型：</span>
            <div class="more-select">
              <el-select v-model="valueTimeType" placeholder="请选择有效期类型">
                <el-option
                  v-for="item in dateArr"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="line" v-if="valueTimeType == '0'">
            <span class="line-title">有效期：</span>
            <div class="edit-input">
              <el-input
                v-model="valueDate"
                @input="inputDelayDays()"
                placeholder="请输入有效期天数"
                clearable
                type="number"
              ></el-input>
              <span>天</span>
            </div>
          </div>
          <div class="line" @click="showDelayDate = true" v-else>
            <span class="line-title">有效期：</span>
            <div class="requst-day-select">
              <span>{{ valueDate }}</span>
              <van-icon name="arrow-down" size="1.3rem" />
            </div>
          </div>
        </template>
        <!-- 不可以修改有效期  paramsObj-->
        <template>
          <div class="line">
            <span class="line-title">有效期：</span>
            <div class="div" v-if="paramsObj.validTimeType == '1'">
              <span>以套明细为准</span>
            </div>
            <div class="requst-day-select" v-else>
              <span
                >{{ paramsObj.timeEnd
                }}{{ paramsObj.timeType == "0" ? "天" : "" }}</span
              >
            </div>
          </div>
        </template>

        <div
          class="line"
          v-if="paramsObj.brandType != '3' || selectType == '5'"
        >
          <span class="line-title">礼券内容：</span>

          <!-- <div class="edit-input" v-if="paramsObj.saleRuleIsDetalis == '1'">
            <el-input
              v-model="valueDetail"
              placeholder="请输入礼券内容"
              clearable
            ></el-input>
          </div> -->
          <span class="details-txt">{{ paramsObj.tktDetails }}</span>
        </div>

        <div
          class="line"
          v-if="paramsObj.brandType != '3' || selectType == '5'"
        >
          <span class="line-title">使用须知：</span>

          <!-- <div class="edit-input" v-if="paramsObj.saleRuleIsNotice == '1'">
            <el-input
              v-model="message"
              placeholder="请输入使用须知"
              clearable
              rows="3"
              type="textarea"
              maxlength="500"
              show-word-limit
            ></el-input>
          </div> -->
          <span class="details-txt">{{ paramsObj.tktNotice }}</span>
        </div>
        <div
          class="line-row"
          v-if="paramsObj.brandType != '3' || selectType == '5'"
        >
          <span>使用限制</span>
          <!-- <el-divider>使用限制</el-divider> -->
        </div>

        <div
          class="line"
          v-if="paramsObj.brandType != '3' || selectType == '5'"
        >
          <span class="line-title">节假日禁用：</span>
          <div class="more-select">
            <el-select
              v-model="dropVacationValue"
              multiple
              :disabled="paramsObj.saleRuleIsLimit != '1'"
              placeholder="暂无节假日"
            >
              <el-option
                v-for="item in dropVacationOptions"
                :key="item.holiday"
                :label="item.holidayCn"
                :value="item.holiday"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <div
          class="time-outer"
          v-if="paramsObj.brandType != '3' || selectType == '5'"
        >
          <div
            class="add-time"
            @click="goAddTime('vacation')"
            v-if="paramsObj.saleRuleIsLimit == '1'"
          >
            <span>添加禁用时间</span>
          </div>

          <div class="time-list">
            <div
              class="time-item"
              v-for="(item, index) in listTimeVacation"
              :key="index"
            >
              <span>{{ item.beginTime }} - {{ item.endTime }}</span>
              <div class="time-btns" v-if="paramsObj.saleRuleIsLimit == '1'">
                <!-- <i class="eicon el-icon-edit" @click=""></i> -->
                <i
                  class="eicon el-icon-delete"
                  @click="deleteVacation(index)"
                ></i>
              </div>
            </div>
          </div>
        </div>
        <div
          class="line"
          v-if="paramsObj.brandType != '3' || selectType == '5'"
        >
          <span class="line-title">周禁用：</span>
          <div class="more-select">
            <el-select
              v-model="dropWeekValue"
              multiple
              :disabled="paramsObj.saleRuleIsLimit != '1'"
              placeholder="暂无周禁用"
            >
              <el-option
                v-for="item in dropWeekOptions"
                :key="item.weekCode"
                :label="item.weekName"
                :value="item.weekCode"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div
          class="time-outer"
          v-if="paramsObj.brandType != '3' || selectType == '5'"
        >
          <div
            class="add-time"
            @click="goAddTime('week')"
            v-if="paramsObj.saleRuleIsLimit == '1'"
          >
            <span>添加禁用时间</span>
          </div>

          <div class="time-list">
            <div
              class="time-item"
              v-for="(item, index) in listTimeWeek"
              :key="index"
            >
              <span>{{ item.beginTime }} - {{ item.endTime }}</span>
              <div class="time-btns" v-if="paramsObj.saleRuleIsLimit == '1'">
                <!-- <i class="eicon el-icon-edit" @click=""></i> -->
                <i class="eicon el-icon-delete" @click="deleteWeek(index)"></i>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="line" v-if="paramsObj.validTimeType != '1'">
          <span class="line-title">日禁用：</span>
        </div> -->
        <div
          class="line line-day"
          v-if="paramsObj.brandType != '3' || selectType == '5'"
        >
          <span class="line-title">日禁用：</span>
          <div class="day-limit">
            <div v-for="(it, i) in dayDateList" :key="i" class="el-date-list">
              <el-date-picker
                v-model="it.value"
                size="small"
                :disabled="paramsObj.saleRuleIsLimit != '1'"
                value-format="yyyy-MM-dd"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="开始日期"
              />
            </div>
          </div>

          <!-- <div class="day-select" v-if="paramsObj.saleRuleIsLimit != '1'">
            <span>{{ date | dateListJoin }}</span>
            <div class="del">
              <van-icon name="clear" />
            </div>
          </div>
          <div class="day-select" v-else>
            <span @click="showDate = true">{{ date | dateListJoin }}</span>
            <div class="del" v-if="date.length > 0" @click="deleteDayList">
              <van-icon name="close" size="2.3rem" />
            </div>
            <div class="select" @click="showDate = true">
              <i class="el-icon-arrow-down"></i>
            </div>
          </div> -->
        </div>
        <div
          class="time-outer"
          v-if="paramsObj.brandType != '3' || selectType == '5'"
        >
          <div
            class="add-time"
            @click="goAddTime('day')"
            v-if="paramsObj.saleRuleIsLimit == '1'"
          >
            <span>添加禁用时间</span>
          </div>

          <div class="time-list">
            <div
              class="time-item"
              v-for="(item, index) in listTimeDay"
              :key="index"
            >
              <span>{{ item.beginTime }} - {{ item.endTime }}</span>
              <!-- <div class="time-btns">
                <i class="eicon el-icon-delete" @click="deleteDay(index)"></i>
              </div> -->
            </div>
          </div>
        </div>
        <!-- 套明细 -->
        <div
          class="line-row"
          v-if="paramsObj.brandType == '3' && selectType != '5'"
        >
          <span>套明细</span>
          <!-- <el-divider>基本信息</el-divider> -->
        </div>
        <div
          class="group-infos"
          v-if="paramsObj.brandType == '3' && selectType != '5'"
        >
          <div
            class="info-list"
            v-for="(item, index) in infoGroup"
            :key="index"
          >
            <div class="info-item">
              <div class="name">
                <span>{{ item.brandName }}</span>
              </div>
              <div class="two-line">
                <div class="line-one">
                  <span class="t">主副券:</span>
                  <span class="txt">{{
                    item.isMain == "1" ? "是" : "否"
                  }}</span>
                </div>
                <div class="line-one">
                  <span class="t">数量:</span>
                  <span class="txt">{{ item.ztcount }}</span>
                </div>
              </div>
              <div class="two-line">
                <div class="line-one">
                  <span class="t">面额:</span>
                  <span class="txt">{{ item.brandPrice }}</span>
                </div>
                <div class="line-one">
                  <span class="t">有效期:</span>
                  <span class="txt"
                    >{{ item.timeEnd
                    }}{{ item.timeType == "0" ? "天" : "" }}</span
                  >
                </div>
              </div>

              <div class="two-line">
                <div class="line-one">
                  <span class="t">使用内容:</span>
                  <span class="txt">{{ item.tktDetails }}</span>
                </div>
              </div>
              <div class="two-line">
                <div class="line-one">
                  <span class="t">使用条款:</span>
                  <span class="txt">{{ item.tktNotice }}</span>
                </div>
              </div>
              <div class="two-line">
                <div class="line-one limit-con">
                  <span class="t">使用限制:</span>
                  <div class="limt-outer">
                    <div class="div">
                      <span class="txt">{{
                        item.holidayLimit == "[]" ? "" : item.holidayLimit
                      }}</span>
                    </div>
                    <div class="div">
                      <span class="txt">{{
                        item.weekLimit == "[]" ? "" : item.weekLimit
                      }}</span>
                    </div>
                    <div class="div">
                      <span class="txt">{{
                        item.dayLimit == "[]" ? "" : item.dayLimit
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="btn-outer">
      <van-button round type="info" color="#006C50" @click="submitSelect"
        >保存</van-button
      >
    </div> -->
    <el-dialog
      title="添加时间"
      :visible.sync="showTimeSelectTime"
      width="90%"
      center
    >
      <div class="dialog-con">
        <div class="dialog-txt">
          <span>请选择你要添加的禁用时间段：</span>
        </div>
        <el-time-picker
          is-range
          value-format="HH:mm"
          format="HH:mm"
          v-model="timeValue"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          placeholder="选择时间范围"
        >
        </el-time-picker>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="showTimeSelectTime = false">取 消</el-button>
        <el-button type="primary" @click="handleAddTime">添 加 </el-button>
      </span>
    </el-dialog>
    <!-- 日禁用  type="multiple"-->
    <van-calendar
      v-model="showDate"
      @confirm="onConfirm"
      type="range"
      :max-date="maxDate"
      :min-date="minDate"
      color="#006C50"
    />
    <!-- 指定生效日期 -->
    <van-calendar
      v-model="showRequestDate"
      @confirm="onConfirmRequstDate"
      :min-date="minDate"
      :max-date="maxDate"
      color="#006C50"
    />
    <van-calendar
      v-model="showOutDate"
      @confirm="onConfirmOutDate"
      :min-date="minDate"
      color="#006C50"
    />
    <!-- 有效期期的选择 -->
    <van-calendar
      v-model="showDelayDate"
      @confirm="onConfirmDelayDate"
      :min-date="minDate"
      :max-date="maxDate"
      color="#006C50"
    />
  </div>
</template>

<script>
import moment from "moment";

import {
  getHolidayList,
  getBrandCombinationBrandList,
  getBnLimitInfo,
} from "@/api/process";
import Integer from "@zxing/library/esm/core/util/Integer";
export default {
  data() {
    return {
      showDate: false,
      dropVacationValue: [],
      dropWeekValue: [],
      valueTimeType: 0,
      groupIndex: 0,
      subIndex: 0,
      infoGroup: [],
      dayDateList: [
        {
          value: "",
        },
      ],
      showDelayDate: false,
      valueDateNum: "",
      paramsObj: {},
      showRequestDate: false,
      showOutDate: false,
      dateArr: [
        {
          value: 0,
          label: "天",
        },
        {
          value: 1,
          label: "日期",
        },
      ],
      selectType: "5", //1.调拨单申请2.销售礼券申请,3免费礼券申请,4销售折扣申请
      //目前2特有申请单价栏
      showTimeSelectTime: false,
      listTimeVacation: [],
      listTimeWeek: [],
      listTimeDay: [],
      timeValue: ["09:00", "11:00"],
      //  timeValue: [new Date(2023, 4, 22, 9, 0), new Date(2023, 4, 22, 11, 0)],
      dropVacationOptions: [],

      dropWeekOptions: [
        {
          weekCode: 1,
          weekName: "周一",
        },
        {
          weekCode: 2,
          weekName: "周二",
        },
        {
          weekCode: 3,
          weekName: "周三",
        },
        {
          weekCode: 4,
          weekName: "周四",
        },
        {
          weekCode: 5,
          weekName: "周五",
        },
        {
          weekCode: 6,
          weekName: "周六",
        },
        {
          weekCode: 7,
          weekName: "周日",
        },
      ],
      selectType: "",
      valueDate: "",
      valuePrice: "",
      valueCount: "",
      valueDiscount: "",
      date: [moment().format("YYYY-MM-DD")],
      dateRequestOut: moment().format("YYYY-MM-DD"),
      dateDelay: moment().format("YYYY-MM-DD"),

      dateRequest: moment().format("YYYY-MM-DD"),

      maxDate: moment().add(20, "years").toDate(),
      minDate: moment().toDate(),

      // minDate: moment()
      //   .subtract(30, "days")
      //   .toDate(),
      optionType: "",
      valueDetail: "",
      valueRules: "",
      message: "",
      price: "",
    };
  },
  filters: {
    dateListJoin(mType) {
      return mType.join("至");
    },
  },
  //初始化
  mounted() {
    // console.log("来页面了啊"+JSON.stringify(JSON.parse(this.$route.query.paramObj)))
    this.paramsObj = JSON.parse(this.$route.query.paramObj);
    this.selectType = this.$route.query.selectType;
    //延期的详情需要走一个接口单独查一下详情来替换参数带过来的详情
    if (this.$route.query.selectType == "5") {
      console.log("延期详情的");
      getBnLimitInfo({
        brandId: this.paramsObj.brandId,
        bnId: this.paramsObj.id,
      })
        .then((rsp) => {
          this.paramsObj = {
            ...this.paramsObj,
            tktDetails: rsp.data.data.tktDetails,
            tktNotice: rsp.data.data.tktNotice,
            isHolidayLimit: rsp.data.data.isHolidayLimit,
            holidayLimit: rsp.data.data.holidayLimit,
            isWeekLimit: rsp.data.data.isWeekLimit,
            weekLimit: rsp.data.data.weekLimit,
            isDayLimit: rsp.data.data.isDayLimit,

            dayLimit: rsp.data.data.dayLimit,
            //...rsp.data.data,
          };
          this.initData();
          //this.dropVacationOptions = rsp.data.data;
        })
        .catch((err) => {
          Toast.fail("获取节假日列表");
        });
    } else {
      this.initData();
    }
  },
  methods: {
    initData() {
      if (this.paramsObj.brandType == "3") {
        this.getBrandListData(this.paramsObj.id);
      }

      this.groupIndex = this.paramsObj.groupIndex; //组index
      this.subIndex = this.paramsObj.subIndex; //子下标index

      // const newList = [...this.$store.state.sendTickCarList];
      // newList[groupIndex].carListbyUser[subIndex].sellPrice = price;
      // 更新 Vuex 数据
      // this.$store.commit("setTickCarList", newList);

      if (this.paramsObj.saleRuleIsValid == "1") {
        //初始化有效期类型
        this.valueTimeType = parseInt(this.paramsObj.timeType);
        this.valueDate = this.paramsObj.timeEnd;
      }

      ////礼券内容
      if (this.paramsObj.saleRuleIsDetalis == "1") {
        this.valueDetail = this.paramsObj.tktDetails;
      }

      if (this.paramsObj.saleRuleIsNotice == "1") {
        //使用须知
        this.message = this.paramsObj.tktNotice;
      }

      //节假日限制
      // console.log(
      //   "````" + JSON.stringify(JSON.parse(this.paramsObj.holidayLimit))
      // );
      let holidayLimitList = JSON.parse(this.paramsObj.holidayLimit);

      let hIds =
        holidayLimitList &&
        holidayLimitList.map((item) => {
          return item.holiday;
        });
      let setHIds = [...new Set(hIds)];
      this.dropVacationValue = [...setHIds];
      this.listTimeVacation =
        holidayLimitList && holidayLimitList.length > 0
          ? [...holidayLimitList[0].timeList]
          : [];
      // console.log("假期id``"+hIds +"排重之后"+setHIds)
      // this.paramsObj.saleRuleIsLimit=1//假开启一下使用显示

      //周限制
      let weekLimitList = JSON.parse(this.paramsObj.weekLimit);
      // console.log("回显周"+JSON.stringify(weekLimitList))
      let weekIds =
        weekLimitList &&
        weekLimitList.length > 0 &&
        weekLimitList.map((item) => {
          return item.weekCode;
        });
      this.dropWeekValue = weekIds;
      // console.log("回显周"+JSON.stringify(this.dropWeekValue))
      this.listTimeWeek =
        weekLimitList && weekLimitList.length > 0
          ? [...weekLimitList[0].timeList]
          : [];
      //this.paramsObj.saleRuleIsLimit=1//假开启一下使用显示

      //日期限制
      let dayLimitList = JSON.parse(this.paramsObj.dayLimit);
      if (dayLimitList && dayLimitList.length > 0) {
        // console.log("有禁用");
        //原始有禁用
        this.dayDateList = [];
        dayLimitList.map((item) => {
          this.dayDateList.push({
            value: [item.beginTime, item.endTime],
          });
        });
        this.listTimeDay = dayLimitList[0].timeList;
        // console.log(this.dayDateList);
      } else {
        //没有禁用
        this.listTimeDay = [];
        this.dayDateList = [{ value: "" }];
      }

      //禁用修改限制
      this.paramsObj.saleRuleIsLimit = 0;

      this.getHolidayListData();
    },
    //获取套明细
    getBrandListData(temId) {
      getBrandCombinationBrandList({ id: temId })
        .then((rsp) => {
          this.infoGroup = rsp.data.data;
          //console.log("套明细" + rsp);
          // this.dropVacationOptions = rsp.data.data;
        })
        .catch((err) => {
          Toast.fail("获取套明细失败");
        });
    },
    //获取节假日列表
    getHolidayListData() {
      getHolidayList()
        .then((rsp) => {
          this.dropVacationOptions = rsp.data.data;
        })
        .catch((err) => {
          Toast.fail("获取节假日列表");
        });
    },
    // showDateRequst(){

    //   this.showRequestDate=true
    // },
    inputDelayDays() {
      let regDayNum = this.valueDate.replace(/[^0-9]/g, "");
      if (regDayNum.indexOf(0) == 0) {
        regDayNum = 0;
      }
      this.valueDate = regDayNum;
    },
    //日禁用
    onConfirm(date) {
      this.showDate = false;

      // console.log(JSON.stringify(limitDayList))
      let limitDayList = date.map((item) => {
        return moment(item).format("YYYY-MM-DD");
      });
      //console.log(JSON.stringify(limitDayList))
      this.date = limitDayList;
    },
    onConfirmDelayDate(date) {
      this.showDelayDate = false;
      this.valueDate = moment(date).format("YYYY-MM-DD");
    },
    onConfirmRequstDate(date) {
      this.showRequestDate = false;
      this.dateRequest = moment(date).format("YYYY-MM-DD");
    },
    onConfirmOutDate(date) {
      this.showOutDate = false;
      this.dateRequestOut = moment(date).format("YYYY-MM-DD");
    },
    deleteVacation(index) {
      this.listTimeVacation.splice(index, 1);
    },
    deleteWeek(index) {
      this.listTimeWeek.splice(index, 1);
    },
    deleteDay(index) {
      this.listTimeDay.splice(index, 1);
    },

    goAddTime(type) {
      this.optionType = type;
      this.showTimeSelectTime = true;
    },
    handleAddTime() {
      //需要处理一下排重
      console.log("当前选择的时间···" + this.timeValue);
      if (this.timeValue && this.timeValue.length > 1) {
        let timeObj = {
          beginTime: this.timeValue[0],
          endTime: this.timeValue[1],
        };
        //this.optionType //vacation节假日禁用
        //针对不同的日期类型分别做处理对应的集合
        if (this.optionType == "vacation") {
          //节假日禁用
          this.listTimeVacation = [...this.listTimeVacation, timeObj];
        } else if (this.optionType == "week") {
          this.listTimeWeek = [...this.listTimeWeek, timeObj];
        } else if (this.optionType == "day") {
          this.listTimeDay = [...this.listTimeDay, timeObj];
        }
      }

      this.showTimeSelectTime = false;
    },
    //提交
    submitSelect() {
      console.log(this.valueDate);
      //    this.groupIndex=this.paramsObj.groupIndex //组index
      // this.subIndex=this.paramsObj.subIndex //子下标index

      const newList = [...this.$store.state.sendTickCarList];
      //newList[this.groupIndex].carListbyUser[this.subIndex]

      //可以改有效期
      if (this.paramsObj.saleRuleIsValid == "1") {
        //有效期类型
        newList[this.groupIndex].carListbyUser[this.subIndex].timeType =
          this.valueTimeType;
        //有效期
        newList[this.groupIndex].carListbyUser[this.subIndex].timeEnd =
          this.valueDate;
      }

      //可以修改指定期望日期
      if (this.paramsObj.saleRuleIsEffect == "1") {
        //指定期望日期
        newList[this.groupIndex].carListbyUser[this.subIndex].effectTime =
          this.dateRequest;
      }

      if (this.paramsObj.saleRuleIsDetalis == "1") {
        //礼券内容
        newList[this.groupIndex].carListbyUser[this.subIndex].tktDetails =
          this.valueDetail;
      }

      if (this.paramsObj.saleRuleIsNotice == "1") {
        //须知
        newList[this.groupIndex].carListbyUser[this.subIndex].tktNotice =
          this.message;
      }
      //节假日限制
      if (this.paramsObj.saleRuleIsLimit == "1") {
        //this.dropVacationValue //假期编码
        //this.listTimeVacation //时间列表
        //  console.log(this.dropVacationValue+"```"+JSON.stringify(this.listTimeVacation))
        //处理数据，准备回传
        //选中的假期列表筛出来
        let checkHolidayList = this.dropVacationOptions.filter((item) => {
          //console.log("选我了吗"+item.holidayCn+this.dropVacationValue.includes(item.holiday))
          return this.dropVacationValue.includes(item.holiday);
        });
        //console.log("选的假期"+JSON.stringify(checkHolidayList))
        //处理最终回传数据格式
        let holidayListParse = [];

        checkHolidayList.forEach((item) => {
          item.dateList.forEach((date) => {
            holidayListParse.push({
              dateName: date,
              holiday: item.holiday,
              timeList: this.listTimeVacation,
              sort: 1,
            });
          });
        });
        // console.log("最终回传格式"+JSON.stringify(holidayListParse))
        //节假日回传的数据给接口
        newList[this.groupIndex].carListbyUser[
          this.subIndex
        ].isHolidayLimit = 1;
        newList[this.groupIndex].carListbyUser[this.subIndex].holidayLimit = [
          ...holidayListParse,
        ];
      }

      //周限制
      if (this.paramsObj.saleRuleIsLimit == "1") {
        //  this.dropWeekValue=[...weekIds]
        // this.listTimeWeek=[...weekLimitList[0].timeList]
        //选折了的周集合
        let checkWeekList = this.dropWeekOptions.filter((item) => {
          return this.dropWeekValue.includes(item.weekCode);
        });
        // console.log("选了周列表有"+JSON.stringify(checkWeekList))
        //处理回传数据
        let weekListParse = checkWeekList.map((item) => {
          return {
            ...item,
            timeList: this.listTimeWeek,
            sort: 1,
          };
        });
        //console.log("周最传的数据"+JSON.stringify(weekListParse))
        //回传数据给接口
        newList[this.groupIndex].carListbyUser[this.subIndex].isWeekLimit = 1;
        newList[this.groupIndex].carListbyUser[this.subIndex].weekLimit = [
          ...weekListParse,
        ];
      }

      //日禁用dayLimitList
      if (this.paramsObj.saleRuleIsLimit == "1") {
        let dayListParse = [
          {
            beginTime: this.date[0],
            endTime: this.date[1],
            timeList: this.listTimeDay,
          },
        ];
        //  console.log("提交的接口``"+JSON.stringify(dayListParse))
        //回传数据给接口
        newList[this.groupIndex].carListbyUser[this.subIndex].isDayLimit = 1;
        newList[this.groupIndex].carListbyUser[this.subIndex].dayLimit = [
          ...dayListParse,
        ];
      }

      // 更新 Vuex 数据
      this.$store.commit("setTickCarList", newList);
      this.$router.back();
    },
    inputChange(item) {
      console.log("输入了" + JSON.stringify(item));
      // this.changeCar(item);
      //看看在不在购物车；里。在的话需要修一下购物车里的item价格
    },
    //一些操作需要更改购物车里信息的
    changeCar(item) {
      const newList = [...this.$store.state.sendTickCarList];
      const index = newList.findIndex((v) => v.id == item.id);
      if (index >= 0) {
        newList[index] = { ...item };
        // 更新 Vuex 数据
        this.$store.commit("setTickCarList", newList);
      }
    },
    onClickLeft() {
      this.$router.back();
    },
  },
};
</script>
<style >
.ticket-request .el-dialog {
  height: 80vh;
  margin-top: 10vh !important;
}
.el-time-range-picker {
  width: 80vw !important;
}
.ticket-request .el-dialog {
  height: 80vh;
  margin-top: 10vh !important;
}
.el-time-range-picker {
  width: 80vw !important;
}
.el-date-range-picker {
  width: 100vw !important;
}
.el-date-range-picker .el-picker-panel__body {
  min-width: 100%;
  display: flex;
  flex-direction: column;
}
.el-date-range-picker .el-picker-panel__body {
  /* min-width: 513px; */
  min-width: 100%;
}
.el-date-range-picker__content {
  width: 100%;
}
.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
  width: 60vw;
}
.el-range-editor--small .el-range-separator {
  width: 2rem;
}
</style>
<style lang="less" scoped>
.group-infos {
  .info-item {
    border-radius: 1rem;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 4px 10px rgba(1, 12, 23, 0.06);
    margin: 1rem 0.5rem;
    border-bottom: 1px dotted #e5e5e5;
    padding: 1.5rem;
    .name {
      border-bottom: 1px dashed #cecece;
      padding-bottom: 1rem;
      font-size: 1.3rem;
    }
    .two-line {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
      .line-one {
        .t {
          color: #999999;
          font-size: 1.3rem;
        }
        .txt {
          color: #333333;
          font-size: 1.3rem;
        }
      }
      .limit-con {
        width: 100% !important;
        display: grid;
        grid-template-columns: 20% 80%;

        .t {
        }
        .txt {
          flex: 1;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
.tick-details-outer {
  position: relative;
  width: 100vw;
  height: 100vh;

  /deep/ .van-field {
    border-bottom: 1px solid #eee;
    padding: 0;
    font-size: 1.3rem;
  }
  /deep/ .van-nav-bar .van-icon {
    color: #333;
  }
  /deep/ .van-nav-bar__text {
    color: #333;
  }
  /deep/ .van-calendar__footer {
    .van-button {
      width: 100%;
    }
  }
  .detail-con-outer {
    padding: 1rem;

    margin-bottom: 10rem;
    .d-title {
      display: flex;
      align-items: center;
      font-size: 1.5rem;
      margin-left: 1rem;
      margin-top: 1rem;
      margin-bottom: 2rem;
      font-weight: 600;
      color: #000;
      .img {
        margin-left: 0.7rem;
        height: 1.6rem;
      }
    }
    .base-detail-outer {
      margin: 1rem;
      //padding: 0.7rem;
      // border-radius: 0.6rem;
      // background-color: #fff;
      // box-shadow: 0rem 0.2rem 0.8rem rgba(1, 12, 23, 0.06);
      .info-txt {
        margin-bottom: 1rem;
        font-size: 1.4rem;
      }
      .line-row {
        font-size: 1.3rem;
        margin-bottom: 2rem;
        margin-top: 2rem;
      }

      .line {
        font-size: 1.3rem;
        color: #333333;
        margin-bottom: 1.5rem;
        display: flex;
        align-items: center;
        .details-txt {
          flex: 1;
        }
        .line-title {
          color: #999999;
          width: 10rem;
        }
        .more-select {
          flex: 1;

          /deep/ .el-select {
            width: 100%;
            font-size: 1rem;
          }
          /deep/ .el-select__tags-text {
            font-size: 1rem;
          }
        }
        .requst-day-select {
          flex: 1;
          display: flex;
          justify-content: space-between;
          font-size: 1.3rem;
        }
        .day-select {
          flex: 1;
          display: flex;
          font-size: 1.5rem;
          padding: 1rem 1rem;

          justify-content: space-between;
        }
        .edit-input {
          flex: 1;
          display: flex;
          align-items: center;
          /deep/ .el-input {
            font-size: 1.3rem;

            width: 15rem;
            border: none;
            border-bottom: 1px solid #ccc;
          }
          /deep/ .el-input__inner {
            height: 3rem;
            border: none;
          }
          /deep/ .el-input__suffix {
            height: 3rem;
            line-height: 3rem;
            display: flex;
            align-items: center;
          }
          /deep/ .el-textarea {
            font-size: 1.3rem;
          }
        }
      }
      .line-day {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
      }
      .line2 {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .line-title {
          margin-bottom: 0.5rem;
        }
        .edit-input {
          margin-left: 10rem;
        }
      }
      .day-limit {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        margin-bottom: 2rem !important;
        .el-date-list {
          margin-bottom: 1rem;
        }
      }
      .time-outer {
        padding-left: 10rem;
        .add-time {
          margin: 0.8rem 0.5rem;
          text-align: end;
          color: #006c50;
          font-size: 1.3rem;
        }

        .time-list {
          .time-item {
            color: #333;
            justify-content: space-between;
            font-size: 1.5rem;
            margin-bottom: 0.7rem;
            display: flex;
            .eicon {
              padding: 0 1rem;
            }
          }
        }
      }
    }
  }

  /deep/ .van-button--normal {
    font-size: 1.6rem;
    width: 80vw;
    height: 4rem;
    padding: 1rem 0;
  }
  .btn-outer {
    display: flex;
    justify-content: center;
    padding: 2rem 0;
    // position: absolute;
    // bottom: 3rem;
    // left: 50%;
    // transform: translateX(-50%);
  }
  .dialog-con {
    font-size: 1.3rem;
    .dialog-txt {
      margin: 1rem 0 2rem;
    }

    /deep/ .el-date-editor {
      width: 90%;
    }
    /deep/ .el-range-input {
      font-size: 1.3rem;
    }
    /deep/ .el-range-separator {
      width: 10%;
    }
  }
}
</style>
